<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <div class="col-md-12">
        <Card :title="'Detail Peringatan Inventori'">
          <template v-slot:body>
            <div class="row align-items-center">
              <!-- Profile Image -->
              <div class="col-md-4">
                <div
                  class="
                    d-flex
                    justify-content-center
                    align-items-center
                    image-container
                  "
                >
                  <div style="height: 350px; width: 350px; position: relative">
                    <img
                      class="image"
                      src="/images/undraw_medical_care_movn.svg"
                      @load="onImageLoad()"
                    />
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div class="col" v-if="imgLoaded != false">
                <table class="table mb-3">
                  <tr>
                    <td><strong>Nama Inventori</strong></td>
                    <td>{{ data.inventory_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Jenis Stok</strong></td>
                    <td>
                      <span v-if="data.stock_in_out == 0">Stok Masuk</span>
                      <span v-if="data.stock_in_out == 1">Stok Keluar</span>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Kuantitas</strong></td>
                    <td>{{ data.quantity }}</td>
                  </tr>
                  <tr>
                    <td><strong>Sisa Stok</strong></td>
                    <td>{{ data.remaining_stock }}</td>
                  </tr>
                  <!-- <tr>
                    <td><strong>Harga</strong></td>
                    <td>{{ data.price ? data.price : "-" }}</td>
                  </tr>
                  <tr>
                    <td><strong>Batch No</strong></td>
                    <td>{{ data.batch_no ? data.batch_no : "-" }}</td>
                  </tr>
                  <tr>
                    <td><strong>Kedaluwarsa</strong></td>
                    <td>{{ data.expired ? data.expired : "-" }}</td>
                  </tr> -->
                  <tr>
                    <td><strong>Catatan</strong></td>
                    <td>{{ data.notes ? data.notes : "-" }}</td>
                  </tr>
                </table>
                <!-- <button
                  @click="
                    $router.push({
                      path: '/inventory/maintain-stock-update/' + data.id,
                    })
                  "
                  class="btn mx-1 btn-success"
                >
                  <i class="far fa-fw fa-edit"></i> Edit
                </button> -->
                <!-- <button class="btn mx-1 btn-danger">
                  <i class="far fa-fw fa-trash-alt"></i> Hapus
                </button> -->
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <!-- List Medicine Stock History -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- <b-alert
              show
              variant="light"
              class="alert alert-custom alert-white alert-shadow fade show gutter-b"
            >
              <div class="alert-icon">
                <span class="svg-icon svg-icon-lg">
                  <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
                </span>
              </div>
              <div class="alert-text">
                Berikut adalah Riwayat Kartu Stok <b>{{ data.inventory_name }}</b>
              </div>
            </b-alert> -->
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
            </span>
            <span class="mb-6"
              >Riwayat Kartu Stok <b>{{ data.inventory_name }}</b></span
            >
            <b-table
              striped
              hover
              :items="items"
              :fields="fields"
              class="mt-6"
              :style="'white-space: nowrap'"
              responsive="md"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }"
                />
              </template>

              <template #cell(stock_in_out)="data">
                <i
                  v-if="data.item.stock_in_out == 0"
                  class="fas fa-arrow-down"
                  style="color: green"
                  v-b-tooltip.hover
                  title="Stok Masuk"
                ></i>
                <i
                  v-if="data.item.stock_in_out == 1"
                  class="fas fa-arrow-up"
                  style="color: red"
                  v-b-tooltip.hover
                  title="Stok Keluar"
                ></i>
              </template>
            </b-table>
            <b-pagination
              v-if="history.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Detail",

  components: {
    Card,
  },

  data() {
    return {
      imgLoaded: false,
      data: [],
      history: [],
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "inventory_name",
          label: "Inventori",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "stock_in_out",
          label: "Jenis Stok",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "quantity",
          label: "Jml",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "remaining_stock",
          label: "Stok sisa",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "vendor_name",
          label: "Penyedia",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "created_at_display",
          label: "Tgl. Input",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
        {
          key: "created_by_name",
          label: "Penginput",
          sortable: true,
          thClass: "th-list-medicines",
          tdClass: "td-list-medicines",
        },
      ],
      filter:{
        inventory_id: '' 
      },
      // Table Head
      items: [],
    };
  },

  methods: {
    onImageLoad() {
      this.imgLoaded = true;
    },

    async deleteData() {
      let result = await module.delete("medicines/" + this.$route.params.id);
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push("/inventory/maintain-stock");
      }
    },

    async get() {
      this.data = await module.get("inventory-stocks/" + this.$route.params.id);
      // this.getMedicineHistory(this.data.medicine_id)
      this.pagination(this.data.inventory_id);
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push("/inventory/maintain-stock");
      }
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination(inventory_id) {
      let filterParams = `&inventory_id=${inventory_id}`;
      let response = await module.paginate(
        "inventory-stocks",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inventori", route: "/inventory/list" },
      { title: "Kartu Stok", route: "/inventory/maintain-stock" },
      { title: "Detail Kartu Stok" },
    ]);

    this.get();
  },
};
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>